import React, { useState } from "react"
import { slide as Menu } from "react-burger-menu"
import InPageScrollLink from "../utils/in-page-scroll-link"
import ColorSwitcher from "../utils/color-switcher/color-switcher"
import styled from "styled-components"
import HomeLinkWithLogo from "../utils/home-link-with-logo"

const StyledColorSwitcherContainer = styled.div`
  margin: 0 auto;
`

const StyledInPageScrollLinkContainer = styled.div`
  color: black;
`

const StyledHomeLinkWithLogoContainer = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
`

const HeaderMobile = ({ links }) => {
  const styles = {
    bmBurgerButton: {
      position: "absolute",
      width: "36px",
      height: "30px",
      right: "36px",
      top: "36px"
    },
    bmBurgerBars: {
      background: "var(--primary)"
    },
    bmBurgerBarsHover: {
      background: "#a90000"
    },
    bmCrossButton: {
      height: "24px",
      width: "24px"
    },
    bmCross: {
      background: "black"
    },
    bmMenuWrap: {
      position: "fixed",
      height: "100%"
    },
    bmMenu: {
      background: "#ffffff",
      padding: "2.5em 1.5em 0",
      fontSize: "1.15em"
    },
    bmMorphShape: {
      fill: "#373a47"
    },
    bmItemList: {
      color: "darkgray",
      padding: "0.8em"
    },
    bmItem: {
      textAlign: "center",
      paddingTop: "25px",
      paddingBottom: "25px"
    },
    bmOverlay: {
      background: "rgba(0, 0, 0, 0.3)"
    }
  }

  const [state, setState] = useState({
    menuOpen: false
  })

  const handleStateChange = (state) => {
    setState({ menuOpen: state.isOpen })
  }

  const closeMenu = () => {
    setState({ menuOpen: false })
  }

  return (
    <Menu styles={styles}
          right
          isOpen={state.menuOpen}
          onStateChange={(state) => handleStateChange(state)}>

      <StyledHomeLinkWithLogoContainer>
        <HomeLinkWithLogo />
      </StyledHomeLinkWithLogoContainer>

      {links.map(link => (
        <StyledInPageScrollLinkContainer>
          <InPageScrollLink to={link.target} onClick={() => closeMenu()}>

            {link.isButton &&
            <button className="gradient-button">
              {link.name}
            </button>}

            {!link.isButton && link.name}

          </InPageScrollLink>
        </StyledInPageScrollLinkContainer>
      ))}

      <StyledColorSwitcherContainer>
        <ColorSwitcher />
      </StyledColorSwitcherContainer>


    </Menu>
  )
}

export default HeaderMobile
