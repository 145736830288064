import React from "react"
import styled from "styled-components"
import HomeLinkWithLogo from "../utils/home-link-with-logo"
import InPageScrollLink from "../utils/in-page-scroll-link"
import ColorSwitcher from "../utils/color-switcher/color-switcher"

const StyledHeader = styled.header`
  width: 100%;
  max-width: 62.5rem;
  margin: 0 auto;
  background: white;
  padding-top: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  a {
    font-size: 1rem;
    font-weight: 500;
  }
`

const HeaderDesktop = ({links}) => {
  return (
    <StyledHeader>
      <HomeLinkWithLogo />

      {links.map(link => (
        <InPageScrollLink key={link.name} to={link.target}>

          {link.isButton &&
          <button className="gradient-button">
            {link.name}
          </button>}

          {!link.isButton && link.name}

        </InPageScrollLink>
      ))}

      <ColorSwitcher/>
    </StyledHeader>
  )
}

export default HeaderDesktop
