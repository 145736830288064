import { createGlobalStyle } from "styled-components"

export const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Carattere&display=swap');


  :root {
    --primary: #9733EE;
    --primaryLight: #DA22FF;
  }

  * {
    box-sizing: border-box;
  }

  html {
    width: 100%;
    height: 100%;
  }

  body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    background-color: white;
    color: black;
    font-size: 1.125rem;
    font-family: "Roboto", Arial, Helvetica, sans-serif;
    line-height: 1.5rem;
    font-weight: 400;
  }

  h1 {
    font-weight: 700;
    font-size: 2rem;
    line-height: 2.375rem;
    color: black;
    @media (min-width: 1200px) {
      font-size: 2.625rem;
      line-height: 4rem;
    }
  }

  h2 {
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.5rem;
    color: black;
    @media (min-width: 1200px) {
      font-size: 2rem;
      line-height: 3rem;
    }
  }

  a {
    display: inline-block;
    text-decoration: none;
    text-decoration-skip-ink: auto;
    color: inherit;
    cursor: pointer;

    &:hover,
    &:focus {
      outline: 0;
    }
  }

  button {
    border: none;
    font-size: 1.2rem;
    font-weight: 900;
    padding: 10px 25px;
    box-shadow: 0 0 20px #eee;
    border-radius: 30px;

    &:hover {
      cursor: pointer;
    }

  }

  .gradient-text {
    background: linear-gradient(to right,
    var(--primaryLight) 0%,
    var(--primary) 51%,
    var(--primaryLight) 100%);
    
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .gradient-button {
    background-image: linear-gradient(to right,
                              var(--primaryLight) 0%,
                              var(--primary) 51%,
                              var(--primaryLight) 100%);

    transition: 0.5s;
    background-size: 200% auto;
    color: white;

    &:hover {
      background-position: right center;
      text-decoration: none;
    }
  }

  .gradient-button-inverted {
    border-top-color: var(--primaryLight);
    border-left-color: var(--primaryLight);
    border-right-color: var(--primary);
    border-bottom-color: var(--primary);
    border-width: 3px;
    border-style: solid;
    background-color: transparent;
    padding: 7px 22px;


    transition: border-color 0.5s;

    &:hover {
      border-top-color: var(--primary);
      border-left-color: var(--primary);
      border-right-color: var(--primaryLight);
      border-bottom-color: var(--primaryLight);
    }

  }
`
