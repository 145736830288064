import React from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPalette } from "@fortawesome/free-solid-svg-icons"

const StyledIcon = styled((props) => <FontAwesomeIcon {...props} />)`
  transition: 0.5s;
`

const ColorSwitcherButton = ({ color, onClick }) => (
  <button className="gradient-button-inverted" onClick={onClick}>
    <StyledIcon icon={faPalette} color={color} />
  </button>
)

export default ColorSwitcherButton
