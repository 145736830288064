import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import HomeLinkWithLogo from "./utils/home-link-with-logo"

const StyledFooter = styled.footer`
  width: 100%;
  height: 6.25rem;
  max-width: 62.5rem;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-size: 0.8rem;
  font-weight: 700;
  padding-top: 100px;
  padding-bottom: 200px;
`

const StyledFooterLink = styled((props) => <Link {...props}/>)`
  font-size: 1.1rem;
  padding-top: 50px;
`

const Footer = () => (
  <StyledFooter>
      <HomeLinkWithLogo/>
      <StyledFooterLink to="/imprint">
        Imprint
      </StyledFooterLink>
      <StyledFooterLink to="/privacy">
        Privacy
      </StyledFooterLink>
  </StyledFooter>
)

export default Footer
