import React from 'react';
import styled from 'styled-components';
import { Link } from "gatsby"

const StyledLogo = styled.div`
  font-size: 2rem;
  font-weight: 900;
  color: black;
`


const HomeLinkWithLogo = () => (
    <Link to="/" aria-label="home">
      <StyledLogo>&lt;t/g&gt;</StyledLogo>
    </Link>
)

export default HomeLinkWithLogo;
