import React, { Fragment } from "react"
import { device } from "../styling/device"
import useMediaQuery from "../styling/use-media-query-hook"
import HeaderDesktop from "./header-desktop"
import HeaderMobile from "./header-mobile"

const Header = () => {
  const isMobileOrTablet = useMediaQuery(device.tablet)

  const links = [
    {
      name: "About",
      target: "#about",
      isButton: false
    },
    {
      name: "Experience",
      target: "#experience",
      isButton: false
    },
    {
      name: "Projects",
      target: "#projects",
      isButton: false
    },
    {
      name: "Contact",
      target: "#contact",
      isButton: true
    }
  ]

  return (
    <Fragment>
      {!isMobileOrTablet && <HeaderDesktop links={links} />}
      {isMobileOrTablet && <HeaderMobile links={links} />}
    </Fragment>
  )
}

export default Header
