import React, { useState } from "react"
import styled from "styled-components"
import ColorSwitcherButton from "./color-switcher-button"
import useMediaQuery from "../../styling/use-media-query-hook"
import { device } from "../../styling/device"

const StyledColorSwitcherContainer = styled.div`
  position: relative;

  .fadeIn {
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
  }

  .fadeOut {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }
`

const StyledColorButton = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  position: absolute;
  top: 60px;
  left: -50px;
  
  :hover{
    filter: brightness(1.2);
    cursor: pointer;
  }
`

export const ColorSwitcher = () => {
  const isMobile = useMediaQuery(device.mobileL)

  const colors = {
    blue: {
      primary: "#090c9b",
      primaryLight: "#3066be"
    },
    red: {
      primary: "#B22222",
      primaryLight: "#ff4769"
    },
    green: {
      primary: "#008000",
      primaryLight: "#32CD32"
    },
    purple: {
      primary: "#9733EE",
      primaryLight: "#DA22FF"
    },
    orange: {
      primary: "#FF8C00",
      primaryLight: "#FFA500"
    }
  }

  const [state, setState] = useState({
    currentColor: colors["purple"],
    showChooseColorButtons: false
  })

  const handleColorSwitch = (color) => {
    document.documentElement.style.setProperty("--primary", colors[color].primary)
    document.documentElement.style.setProperty("--primaryLight", colors[color].primaryLight)

    setState({
      ...state,
      currentColor: colors[color],
      showChooseColorButtons: false
    })
  }

  const handleColorSwitcherClick = () => {
    setState({
      ...state,
      showChooseColorButtons: !state.showChooseColorButtons
    })
  }

  const calculateStyledColorButtonStyle = (color, index) => {
    const firstButtonPosition = isMobile ? 15 : -50
    const offsetToApply = 35

    const left = firstButtonPosition + (offsetToApply * index)

    return {
      background: `radial-gradient(${colors[color].primaryLight}, ${colors[color].primary})`,
      left
    }
  }

  return (
    <StyledColorSwitcherContainer>
      <ColorSwitcherButton color={state.currentColor.primary}
                           onClick={handleColorSwitcherClick} />

      {Object.entries(colors).map(([key], index) => (
        <StyledColorButton key={key}
                           className={state.showChooseColorButtons ? "fadeIn" : "fadeOut"}
                           onClick={() => handleColorSwitch(key)}
                           style={calculateStyledColorButtonStyle(key, index)} />
      ))}

    </StyledColorSwitcherContainer>
  )
}

export default ColorSwitcher
